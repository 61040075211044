/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/regga-rantai-profile-pic.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 50
            height: 50
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const avatar = getImage(data?.avatar?.childImageSharp?.gatsbyImageData)

  return (
    <div className="bio">
      {avatar && (
        <GatsbyImage
          image={avatar}
          alt={author?.name}
          layout="fixed"
          className="bio-avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
      {author?.name && (
        <p>
          Posted by <strong>Regga</strong> | @{author.name}
          <br/>
          <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/reggarantai">Instagram</a>&nbsp;&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/reggarantai">Facebook</a>&nbsp;&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="skype:reggarantai?chat">Skype</a>&nbsp;&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://reggarantai.com/">Works</a>
        </p>
      )}
    </div>
  )
}

export default Bio
