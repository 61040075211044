import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import _ from "lodash"

import 'animate.css/animate.css'

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { GatsbyImage } from "gatsby-plugin-image";

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const fi = post.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src,
  hasTags = post.frontmatter?.tags?.length,
  quotes = data.quotes?.edges,
  defImg = data.defImg?.childImageSharp?.resize?.src

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.featuredImage.childImageSharp.resize.src}
        url={post.fields.slug}
        meta={[
          {
            name: `keywords`,
            content: ['regga', 'regga rantai'].concat(post.frontmatter.tags).concat(['reggarantai']).join(",")
          },
          {
            property: `og:type`,
            content: `article`
          },
          {
            property: `og:image:width`,
            content: `${post.frontmatter.featuredImage.childImageSharp.resize.width}`
          },
          {
            property: `og:image:height`,
            content: `${post.frontmatter.featuredImage.childImageSharp.resize.height}`
          },
          {
            property: `og:image:type`,
            content: `image/jpeg`
          },
          {
            property: `article:section`,
            content: post.frontmatter.description || post.excerpt
          },
          {
            property: `article:tag`,
            content: ['regga rantai'].concat(post.frontmatter.tags).concat(['reggarantai']).join(",")
          }
        ]}
      />
      <div className="blog-post">
        <div>
          <div style={{
            backgroundColor: '#000',
            backgroundSize: 'cover',
            backgroundImage: `url(${fi})`,
            backgroundPosition: 'center center'
          }}>
            <div id="head-bg">
              <br /><br />
              <header>
                {
                  post.frontmatter.postType !== 'blog' ? (
                    <h4>{post.frontmatter.postType}</h4>
                  ) : null
                }
                <div className="animate__animated animate__pulse">
                  <h1 itemProp="headline">{post.frontmatter.title}</h1>
                </div>
                <div className="line"/>
                <p>{post.frontmatter.hijriah} | {post.frontmatter.date}</p>
              </header>
            </div>
          </div>
        </div>
        <div className="global-wrapper">
          <article>
          <section>
            <MDXProvider>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
          </section><br/>
            <p style={{marginLeft: -5,marginRight: -5}}>

              <a className="m-only resp-sharing-button__link" href={`whatsapp://send?text=*${post.frontmatter.title}*%0a%0a_${post.frontmatter.description}_%0a%0a${encodeURIComponent(`https://regga.id${post.fields.slug}`)}`} target="_blank" rel="noreferrer" aria-label="Share on WhatsApp">
                <span className="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--large"><span aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"/></svg>
                  </span><span>Bagikan: WhatsApp</span></span>
              </a>

              <a className="resp-sharing-button__link" href={`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://regga.id${post.fields.slug}`)}`} target="_blank" rel="noreferrer" aria-label="Share on Facebook">
                <span className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--large"><span aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
                  </span><span>Bagikan: Facebook</span></span>
              </a>

              <a className="resp-sharing-button__link" href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(`https://regga.id${post.fields.slug}`)} ${post.frontmatter.title} - ${post.frontmatter.description}`} target="_blank" rel="noreferrer" aria-label="Share on Twitter">
                <span className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--large"><span aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
                  </span><span>Bagikan: Twitter</span></span>
              </a>

              <a className="resp-sharing-button__link" href={`mailto:?subject=${post.frontmatter.title}&body=${post.frontmatter.description}%0D%0A${encodeURIComponent(`https://regga.id${post.fields.slug}`)}`} target="_self" rel="noreferrer" aria-label="Share by E-Mail">
                <span className="resp-sharing-button resp-sharing-button--email resp-sharing-button--large"><span aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z"/></svg></span><span>Bagikan: Email</span></span>
              </a>
            </p>

            <div className="end-post-line">_ _ _</div><br/>
            <Bio/>
            {
              hasTags !== 0 && (
                <div className="tag-list">
                  <div>
                    <span>Post tag{hasTags > 1 ? `s` : ``}:</span>
                  </div>
                  <div>
                    {
                      post.frontmatter.tags?.map((tag) => {
                        return (<Link to={`/${_.kebabCase(tag)}/`} key={_.kebabCase(tag)}>{tag}</Link>)
                      })
                    }
                  </div>
                </div>
              )
            }
            {
              post.frontmatter.postType !== 'blog' && (
                <div className="tag-list">
                  <div>
                    <span>Post type:</span>
                  </div>
                  <div>
                    <Link to={`/${post.frontmatter.postType}/`}>{post.frontmatter.postType}</Link>
                  </div>
                </div>
              )
            }
          </article>
        </div>

        <footer>
          <div className="container section-title section-title-white"><div className="row"><div className="col-sm-12">
              <h2>Lainnya</h2>
          </div></div></div>
          <div className="global-wrapper">
            <nav className="blog-post-nav">
              <ul
                style={{
                  display: `flex`,
                  listStyle: `none`,
                  padding: 0,
                  marginLeft: '-2%',
                  marginRight: '-2%'
                }}
              >
                {previous && (
                  <li className="card" style={{flex:`0 0 46%`,maxWidth:'46%',margin:`${next ? '2%' : 'auto'}`}}>
                    <Link to={previous.fields.slug} rel="prev">
                      <GatsbyImage
                        alt={previous.frontmatter.title}
                        image={previous.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData || ''}
                        objectFit="cover"
                        objectPosition="50% 50%" />
                      <div className="card-body">
                        <small className="card-date">{previous.frontmatter.hijriah}</small>
                        <h3 style={{fontSize:"0.9rem"}}>{previous.frontmatter.title}</h3>
                      </div>
                    </Link>
                  </li>
                )}

                {next && (
                  <li className="card" style={{flex:`0 0 46%`,maxWidth:'46%',margin:`${previous ? '2%' : 'auto'}`}}>
                    <Link to={next.fields.slug} rel="next">
                    <GatsbyImage
                      alt={next.frontmatter.title}
                      image={next.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData || ''}
                      objectFit="cover"
                      objectPosition="50% 50%" />
                    <div className="card-body">
                      <small className="card-date">{next.frontmatter.hijriah}</small>
                      <h3 style={{fontSize:"0.9rem"}}>{next.frontmatter.title}</h3>
                    </div>
                    </Link>
                  </li>
                )}

              </ul>
            </nav>
            <br/><Link className="back-to-home" to="/">&laquo; Kembali ke halaman utama</Link><br/>
          </div>
        </footer>
      </div>

      {
        post.frontmatter.postType !== 'kutipan' && quotes ? (
          <section id="quotes" className="section" style={{
              backgroundSize: 'cover',
              backgroundImage: `url(${defImg || ''})`,
              backgroundColor: '#fff',
              backgroundPosition: 'top center'
            }}>
              <div className="overlay"></div>
              <div className="container section-title section-title-white"><div className="row"><div className="col-sm-12">
                  <h2>Kutipan</h2>
              </div></div></div>
              <div className="container cards cards-transparent">
                <div className="row" data-sal="fade"
                data-sal-duration="2000"
                data-sal-easing="ease">
                  {
                    quotes?.map(({ node}) => {
                      return (
                        <div key={node.fields.slug} className="card3">
                          <div className="card-inner">
                            <Link to={node.fields.slug}>
                              <GatsbyImage
                                alt={node.frontmatter.title}
                                image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                                objectFit="cover"
                                objectPosition="50% 50%" />
                            </Link>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>

            </section>
        ) : null
      }

    </Layout>
  );
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostBySlug($slug: String!, $previousPostId: String, $nextPostId: String) {
  site {
    siteMetadata {
      title
      image
    }
  }
  mdx(fields: {slug: {eq: $slug}}) {
    id
    body
    fields {
      slug
    }
    excerpt(pruneLength: 160)
    body
    frontmatter {
      title
      date(formatString: "DD MMMM YYYY")
      hijriah
      description
      postType
      tags
      featuredImage {
        childImageSharp {
          resize(width: 1280, quality: 95) {
            src
            width
            height
          }
          gatsbyImageData(width: 20, layout: FIXED)
        }
      }
    }
  }
  previous: mdx(id: {eq: $previousPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
      hijriah
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 640, layout: CONSTRAINED)
        }
      }
    }
  }
  next: mdx(id: {eq: $nextPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
      hijriah
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 640, layout: CONSTRAINED)
        }
      }
    }
  }
  quotes: allMdx(
    filter: {frontmatter: {postType: {eq: "kutipan"}}}
    limit: 4
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 640, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  defImg: file(relativePath: {eq: "images/regga-coffee.jpg"}) {
    childImageSharp {
      resize(width: 10) {
        src
      }
    }
  }
}
`
